import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";
import { formatDate, formatDateTime } from "../utils";
import { toast } from "react-toastify";

const ListContrct = () => {
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [filtered, setFiltered] = useState([]);
    const [contracts, setContracts] = useState([]);
    const { user } = useAuth();

  
    const toastStyle = { fontSize: "13.3px" };
    

    const columnTitles = [
        { key: "created_at", value: "Data Criação" },
        { key: "initialDate", value: "Data Inicial" },
        { key: "finalDate", value: "Data Final" },
        { key: "name", value: "Contrato" },
      
        { key: "contratType", value: "Fonte/Utilizador" },
    ];

    const getContracts = useCallback(async () => {
        try {
            const response = await axios.get(`${url}/contract/all`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });

            // Mapear os contratos da API
            const data = response.data.map((v) => ({
                ...v,
                created_at: formatDateTime(v.created_at),
                initialDate: formatDate(v.initialDate),
                finalDate: formatDate(v.finalDate),
                company: v.company.name,
                contratType: v.company?.isExternalCompany ? "Externo" : "Interno",
                name: v.name,
            }));

            // Ordenar por data de criação
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            setContracts(data);
            setFiltered(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [url, user.access_token]);

    useEffect(() => {
        getContracts();
    }, [getContracts]);

    const deleteContract = async (id) => {
        try {
            await axios.delete(`${url}/contract/${id}`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });
            toast.success("Eliminado com sucesso", { style: toastStyle });
            await getContracts();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao Eliminar", { style: toastStyle });
        }
    };

    const filter = (e) => {
        const pattern = RegExp(e.target.value, "i");
        const results = contracts.filter(
            (contract) =>
                contract.name.match(pattern) || contract.company.match(pattern)
        );
        setFiltered(results);
    };

    return (
        <List
            title="Contrato"
            columnTitles={columnTitles}
            items={filtered}
            filter={filter}
            originLink="/listContract#"
            newLink="/addContract"
            viewBaseLink="/viewContract"
            editBaseLink="/addRoadContract"
            deleteItem={deleteContract}
            loading={loading}
        />
    );
};

export default ListContrct;
