import logo2 from "../logo.svg";
import { Link } from "react-router-dom";
import { Dropdown } from "./dropdown";
import { useAuth } from "react-oidc-context";
import { RoleEnum } from "../../constants";
import { useEffect, useState } from "react";

export const SideBar = () => {
  const { user } = useAuth();
  const [isGestor, setIsGestor] = useState(false);
  const estradas = [
    { label: "Ilha", to: "/listIsland" },
    { label: "Zona", to: "/listZone" },
    { label: "Utilizadores", to: "/listUser" },
  ];

  useEffect(() => {
    setIsGestor(user.profile.roles?.includes(RoleEnum.gestor) ?? false);
  }, [user.profile]);

  return (
    <nav
      id="sidebarMenu"
      className="collapse d-lg-block sidebar collapse sidItemL"
      style={{ zIndex: 1031 }}
    >
      <div
        className="position-sticky overflow-auto "
        style={{ maxHeight: "500px" }}
      >
        <div className="ms-3 mb-1">
          <img className="lg" src={logo2} style={{ width: "60px" }} alt="" />
        </div>
        <hr className="text-white" />

        <div className="list-group list-group-flush mx-6">
          <Link
            to="/"
            className="list-group-item list-group-item-action pb-3 ripple sidItemL text-white text-hover"
          >
            <i className="fas fa-home me-3 text-white"></i>
            <span>Home</span>
          </Link>
          {isGestor && (
            <>
              <Dropdown
                title="Parametrização"
                icon="fas fa-cogs me-3 text-white"
                items={estradas}
              />
              {/* <Dropdown
            title="Contratos"
            icon="fas fa-file-alt fa-fw me-3 text-white"
            items={contratos}
          /> */}
              <Link
                to="/listRoad"
                className="list-group-item list-group-item-action pb-3 ripple sidItemL text-white text-hover"
              >
                <i className="fas fa-road fa-fw me-3 text-white"></i>
                <span>Estradas</span>
              </Link>
              <Link
                to="/listCompany"
                className="list-group-item list-group-item-action pb-3 ripple sidItemL text-white text-hover"
              >
                <i class="fas fa-building fa-fw me-3 text-white"></i>
                <span>Empresas</span>
              </Link>
            </>
          )}
          <Link
            to="/listContract"
            className="list-group-item list-group-item-action pb-3 ripple sidItemL text-white text-hover"
          >
            <i class="fas fa-file-alt fa-fw me-3 text-white"></i>
            <span>Contratos</span>
          </Link>
          {isGestor && (
            <Link
              to="/roadMap"
              className="list-group-item list-group-item-action pb-3 ripple sidItemL text-white text-hover"
            >
              <i class="fas fa-map-marked me-3 text-white"></i>
              <span>Mapa</span>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};
