import { Link } from "react-router-dom";
import { Pagination } from "./Pagination";
import { useEffect, useState } from "react";
import ConfirmModal from "./modal/ConfirmModal";
import { RoleEnum } from "../constants";
import { useAuth } from "react-oidc-context";


const List = ({
  title = "Items",
  columnTitles = [],
  items = [],
  deleteItem = () => {},
  filter = () => {},
  originLink = "",
  viewBaseLink = "",
  editBaseLink = "",
  newLink = "",
  loading = true,
  showEditButton = true,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPorPagina] = useState(10);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
    const { user } = useAuth();
  const indexDoUltimoItem = paginaAtual * itensPorPagina;
  const indexDoPrimeiroItem = indexDoUltimoItem - itensPorPagina;

  const itensAtuais = items.slice(indexDoPrimeiroItem, indexDoUltimoItem);

  const paginate = (numeroDaPagina) => setPaginaAtual(numeroDaPagina);
  const [isGestor, setIsGestor] = useState(false);
  useEffect(() => {
    setIsGestor(user.profile.roles?.includes(RoleEnum.inspector) ?? false);
  }, [user.profile]);
  console.log(user.profile.roles)

  const handleDeleteClick = (id) => {
    setSelectedItemId(id);
    setModalVisible(true);
  };

  const confirmDelete = () => {
    deleteItem(selectedItemId);
    setModalVisible(false);
  };

  return (
    <div className="container-sm pt-4">
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto" }}>
        Listagem de {title}
      </h5>

      <div className="clearfix mb-2">
      {title !== "Contrato" || !isGestor ? (
    <Link
      to={newLink}
      className="btn btn-sm sidItemL2 float-end text-light"
    >
      <i className="fas fa-plus-circle pe-1"></i>Novo
    </Link>
  ) : null}

        <input
          type="search"
          onChange={filter}
          className="form-control float-start w-25 fas fa-search bg-white"
          placeholder="&#xf002;"
        />
      </div>

      <div class="table-responsive-sm ">
        <table className=" table table-sm shadow bg-white align-middle p-3 text-center  text-justify ">
          {loading && <caption className="spinner-border ic2"></caption>}
          <thead className="mediunText ">
            <tr>
             
              {columnTitles.map((column) => (
                <th key={column.key} className="">
                  {column.value}
                </th>
              ))}
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody className="smollText border border-top-0  text-justify">
            {items.length > 0 ? (
              itensAtuais.map((item, index) => (
                <tr key={item.id} className="">
                
                  {columnTitles.map((column) => (
                    <td key={item[column.key]} className="ps-2">
                      {item[column.key]}
                    </td>
                  ))}
                  <td className="text-center">
                    <Link
                      to={`${viewBaseLink}/${item.id}`}
                      className="btn fas fa-eye p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    ></Link>
                    
                      <Link
                        to={`${editBaseLink}/${item.id}`}
                        className="btn fa fa-edit p-2 ic2 smollText"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Editar"
                      ></Link>
                      {item.contratType !== "Externo" && item.source !== "new"  && (
                    <button
                    onClick={() => handleDeleteClick(item.id)}
                    className="btn fas fa-trash-alt p-2 ic2 smollText"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Deletar"
                  ></button>
                  )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columnTitles.length + 2}>
                  <h4 className="text-center pt-5 ic2">Sem Resultado!</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagination
          paginaAtual={paginaAtual}
          itensPorPagina={itensPorPagina}
          itensTotais={items.length}
          originLink={originLink}
          paginate={paginate}
        />
      </div>

      <ConfirmModal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={confirmDelete}
        message="Tem certeza de que deseja deletar este item?"
      />
    </div>
  );
};

export default List;
